:root {
	--font-1: 'Rubik', sans-serif;

	--blue-1: #4b27ef;
	--blue-2: #d6d8e8;
	--blue-3: #f6f6fc;
	--blue-4: #eff1fd;
	--blue-5: #9e9ef7;
	--blue-6: #6094e3;

	--gray-1: #757575;
	--gray-2: #d9d9d9;
	--black-1: #17191e;
	--black-2: #394154;
	--pink-1: #ea345f;
	--pink-2: #ff3160;

	--elevation-1: 0 4px 4px rgba(0 0 0 /0.3);
	--elevation-2: 0 0px 4px rgba(0 0 0 /0.3);
	--elevation-3: 0 0px 2px #17191e;
	--elevation-4: 1px 3px 4px rgb(0 0 0 / 0.3);
	--elevation-5: 0 0px 2px #ff3160;
	--degradient-1: linear-gradient(to top left, #4b27ef, #ff3160);

	--white: #ffffff;
	--black: #000000;

	--bgContent: #f6f6fc;
}

@media (prefers-color-scheme: dark) {
	:root {
		--font-1: 'Rubik', sans-serif;

		--blue-1: #4b27ef;
		--blue-2: #d6d8e8;
		--blue-3: #f6f6fc;
		--blue-4: #eff1fd;
		--blue-5: #9e9ef7;
		--blue-6: #6094e3;

		--gray-1: #757575;
		--gray-2: #d9d9d9;
		--black-1: #17191e;
		--black-2: #394154;
		--pink-1: #ea345f;
		--pink-2: #ff3160;

		--elevation-1: 0 4px 4px rgba(0 0 0 /0.3);
		--elevation-2: 0 0px 4px rgba(0 0 0 /0.3);
		--elevation-3: 0 0px 2px #17191e;
		--elevation-4: 1px 3px 4px rgb(0 0 0 / 0.3);
		--elevation-5: 0 0px 2px #ff3160;
		--degradient-1: linear-gradient(to top left, #4b27ef, #ff3160);

		--white: #ffffff;
		--black: #000000;

		--bgContent: #f6f6fc;
	}
}

// Class

%title_s1 {
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	color: var(--black-1);
}
%text_s1 {
	font-family: var(--font-1);
	color: var(--black-1);
	font-style: normal;
	font-weight: 400;
	font-size: 21px;
}
%textModule {
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	color: var(--blue-1);
}

%navModule {
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: var(--blue-1);
}
%navModule-menu {
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
}
%navModule-subMenu {
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	text-transform: uppercase;
	color: var(--black-2);
}
%navPage {
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: var(--black-2);
}

%headerRow {
	font-family: var(--font-1);
	color: var(--black-1);
	font-size: 12px;
	font-weight: 700;
	line-height: 14px;
	text-align: center;
}
%Row {
	font-family: var(--font-1);
	color: var(--black-1);
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	text-align: center;
}

%label-s1 {
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: var(--gray-1);
}
