// The min screen is 320px.
// Mobile first

@mixin mobile_m {
	@media screen and (min-width: 375px) {
		@content;
	}
}

@mixin mobile_l {
	@media screen and (min-width: 425px) {
		@content;
	}
}

@mixin table_m {
	@media screen and (min-width: 768px) {
		@content;
	}
}

@mixin laptop_m {
	@media screen and (min-width: 1024px) {
		@content;
	}
}

@mixin desktop_m {
	@media screen and (min-width: 1440px) {
		@content;
	}
}

@mixin splitCustom($min: 1440px) {
	@media screen and (min-width: $min) {
		@content;
	}
}
