@use '/src/scss/mixin.scss' as mixin;

*,
*:before,
*:after {
	box-sizing: border-box;
	font-family: var(--font-1);
}

html,
body {
	width: 100%;
	height: 100%;
	min-width: 320px;
	padding: 0;
	margin: 0;
	overflow: hidden;
}

html {
	font-size: 62.5%;
}

body {
	font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
	margin: 0;
	padding: 0;
}

input,
textarea,
button {
	outline: none;
	border: none;
	background: none;
	margin: 0;
	padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px transparent inset;
	transition: background-color 5000s ease-in-out 0s;
}

dialog {
	border: none;
	padding: 0;
	margin: 0;
	position: static;
	color: #000000;
}

// Width
::-webkit-scrollbar {
	width: 5px;
	@include mixin.table_m {
	}
}

// Track
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-right: 1px solid #ffffff;
	@include mixin.table_m {
	}
}

// Handle
::-webkit-scrollbar-thumb {
	background: #d1d1d1;
	border-right: 1px solid #ffffff;
	@include mixin.table_m {
	}
}

// Handle on hover
::-webkit-scrollbar-thumb:hover {
	background: #868282;
	@include mixin.table_m {
	}
}

svg {
	display: block;
	object-fit: contain;
	width: 50vw;
	max-width: 100%;
	max-height: 100%;
}
