/* You can add global styles to this file, and also import other style files */
// @use '/src/scss/variables' as variables;
// @use '/src/scss/reset' as reset;
@use '/src/scss/mixin' as mixin;
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

.vlbtn {
	padding: 7px 14px;
	display: grid;
	place-items: center;
	border-radius: 20px;
	border: 1px solid var(--pink-1);
	background-color: var(--pink-1);
	color: var(--white);
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	text-decoration: none;
	text-transform: capitalize;
	&:not(:disabled) {
		cursor: pointer;
	}

	&.alt {
		background-color: var(--white);
		color: var(--pink-1);
	}

	&:disabled {
		border: 1px solid var(--blue-2);
		background-color: var(--blue-2);
		color: var(--white);
		&.alt {
			border: 1px solid var(--blue-2);
			background-color: var(--white);
			color: var(--blue-2);
		}
	}
}

.vlbtn-filter {
	padding: 7px 14px;
	display: grid;
	place-items: center;
	border-radius: 6px;
	border: 1px solid var(--blue-1);
	background-color: var(--blue-1);
	color: var(--white);
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	text-decoration: none;
	&:not(:disabled) {
		cursor: pointer;
	}

	&.alt {
		background-color: var(--white);
		color: var(--blue-1);
	}

	&:disabled {
		border: 1px solid var(--blue-2);
		background-color: var(--blue-2);
		color: var(--white);
		&.alt {
			border: 1px solid var(--blue-2);
			background-color: var(--white);
			color: var(--blue-2);
		}
	}
}

.vl-btn-onlyBorder {
	padding: 7px 14px;
	display: grid;
	place-items: center;
	border-radius: 20px;
	border: 1px solid var(--pink-1);

	font-family: var(--font-1);
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	text-decoration: none;
	text-transform: capitalize;
	background-color: transparent;
	color: var(--pink-1);
	&:not(:disabled) {
		cursor: pointer;
	}
}

.vl-table {
	background-color: var(--white);
	box-shadow: var(--elevation-2);
	border-radius: 6px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	&__top {
		display: flex;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		padding: 15px;
		gap: 12px;
		align-items: center;
		justify-content: flex-end;
	}
}

.btn-trigger-table {
	width: 36px;
	height: 36px;
	display: grid;
	place-items: center;
	background-color: var(--white);
	box-shadow: var(--elevation-3);
	border-radius: 6px;
	transition: all 150ms ease-in-out;
	padding: 8px;
	cursor: pointer;
	color: var(--gray-1);

	position: relative;

	&:hover {
		color: var(--black-1);
	}
	&:active {
		transform: scale(0.9);
	}

	&__count {
		position: absolute;
		top: -7px;
		right: 0;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background: var(--blue-1);
		color: var(--white);
		display: grid;
		place-items: center;
		font-size: 10px;
		text-align: center;
	}
}

.errorMsg {
	font-size: 10px;
	color: red;
	font-family: var(--font-1);
}

.vl-section {
	display: flex;
	flex-direction: column;
	gap: 12px;
	&__title {
		font-family: var(--font-1);
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: var(--black-1);
	}
}

.vl-checkbox-form {
	display: grid;
	grid-template-columns: 20px 1fr;
	grid-template-rows: auto;
	column-gap: 6px;
	text-align: left;
	cursor: pointer;
	&__wrapper {
		position: relative;
		display: grid;
		place-items: center;
		width: 16px;
		height: 16px;
		border-radius: 4px;
		cursor: pointer;

		input {
			display: none;

			&:checked ~ .vl-checkbox-form__wrapper__check {
				background-color: var(--pink-2);

				&::after {
					border-color: white;
				}
			}
		}
		.vl-checkbox-form__wrapper__check {
			border-radius: 4px;
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			display: grid;
			justify-items: center;
			box-shadow: var(--elevation-3);
			background: var(--white);
			&:after {
				content: '';
				position: absolute;
				display: block;
				width: 5px;
				height: 9px;
				margin-top: 3px;
				border: solid transparent;
				border-width: 0 2px 2px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}

	&__text {
		font-family: var(--font-1);
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		color: #000000;
	}
}
.vl-switch-btn {
	display: flex;
	flex-direction: row;
	gap: 12px;
	&__text {
		font-family: var(--font-1);
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		color: #000000;
		cursor: pointer;
	}
}
.notDataText {
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	color: #757575;
}

.vl-box-account {
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	max-width: 900px;
	padding: 31px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	&__header {
		display: flex;
		gap: 12px;
		align-items: center;
		&__title {
			flex-grow: 1;
			font-weight: 400;
			font-size: 25px;
			line-height: 30px;
			color: var(--black-1);
		}
		&__btnEdit {
			display: grid;
			width: 16px;
			height: 16px;
			cursor: pointer;
		}
		&__back {
			width: 28px;
			height: 28px;
			display: grid;
			place-items: center;
			text-decoration: none;
			color: currentColor;
			cursor: pointer;
			&:active {
				transform: scale(0.9);
			}
		}
	}
	&__details {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: 20px;
		list-style: none;
		&__detail {
			display: grid;
			grid-template-columns: 1fr;
			align-items: center;

			@include mixin.laptop_m {
				grid-template-columns: 1fr 1fr;
			}

			&__label {
				font-size: 16px;
				line-height: 19px;
				text-transform: uppercase;
				color: #757575;
			}
			&__value {
				font-size: 20px;
				line-height: 24px;
				color: #000000;
				border-radius: 6px;
				border: 1px solid transparent;
				height: 43px;
				padding: 0 20px;
				display: flex;
				align-items: center;
			}
			&__link {
				font-size: 16px;
				line-height: 24px;
				color: var(--blue-1);
				text-decoration: none;
				cursor: pointer;
				padding: 0 20px;
			}
			&__inputs {
				display: flex;
				gap: 5px;
				align-items: center;
				position: relative;
				&__s1 {
					width: 80px;
					font-size: 20px;
					line-height: 24px;
					color: #000000;

					border-radius: 6px;
					border: 1px solid transparent;
					height: 43px;
					padding: 0 20px;
					cursor: default;

					&::placeholder {
						font-size: 20px;
						line-height: 24px;
						color: #000000;
					}
				}

				&__s2 {
					width: 100%;
					font-size: 20px;
					line-height: 24px;
					color: #000000;
					height: 43px;
					padding: 0 20px;

					display: flex;
					align-items: center;

					background: #ffffff;
					box-shadow: var(--elevation-3);
					border-radius: 4px;

					&::placeholder {
						font-size: 20px;
						line-height: 24px;
						color: #000000;
					}

					&:read-only {
						cursor: default;
						box-shadow: none;
					}
				}
				&__error {
					position: absolute;
					top: calc(100% + 5px);
					font-size: 10px;
					color: red;
					font-family: var(--font-1);
				}
			}
		}
		&__actions {
			display: flex;
			justify-content: flex-end;
			gap: 12px;
			flex-direction: column;
			@include mixin.laptop_m {
				flex-direction: row;
			}
			.vlbtn {
				min-width: 188px;
			}
		}
	}
}

.cdk-overlay-container {
	z-index: 999999999999999999999999999999 !important;
}

.vl-switch {
	--h: 14px;
	--w: 40px;
	position: relative;
	width: var(--w);
	height: var(--h);
	display: flex;
	align-items: center;
	border-radius: 14px;
	background: #e6e7f3;
	transition: background linear 250ms;
	cursor: pointer;

	input {
		display: none;
	}

	&__slider {
		display: block;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: #848fab;
		transition: transform linear 250ms;
	}
	&.--active {
		background: #b0a2ed;

		.vl-switch__slider {
			background: #6848f3;
			transform: translateX(calc(100% + 4px));
		}
	}
}

.cdk-virtual-scrollable {
	contain: initial !important;
}
.cdk-virtual-scroll-content-wrapper {
	max-width: 100%;
}

.grid-form-anl {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(min(100%, 180px), 1fr));
	column-gap: 12px;
	// row-gap: 12px;
	grid-auto-flow: row;
	align-items: flex-end;
	& .vl-form-group-s1__label {
		font-family: var(--font-1);
		font-style: normal;
		font-weight: 700 !important;
		font-size: 12px !important;
		line-height: 14px;
		color: var(--black-1) !important;
	}

	.vlbtn-filter {
		height: 43px;
		margin-bottom: 20px;
	}
}
.vl-line-split-content {
	margin: 6px auto;
	width: 100%;
	border: 1px solid #848fab;
}

.pt-155 {
	padding-top: 155px;
}
.vl-code {
	&-sim {
		color: #cbd5e1;
	}
	&-tag {
		color: var(--blue-6);
	}
	&-att {
		color: var(--white);
	}
	&-val {
		color: var(--pink-2);
	}
	&-spc {
		display: inline-block;
		width: 19px;
		height: 19px;
	}
}
